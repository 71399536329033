<template>
  <div>
    <b-button
      size="sm"
      variant="outline-primary"
      class="mr-1"
      @click="exportTo()"
    >
      <feather-icon
        icon="DownloadIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="text-nowrap">{{ titleButton }}</span>
    </b-button>
  </div>
</template>

<script>
import { exportToExcel, exportToPDF } from '@core/utils/utils'
import moment from 'moment'
import { t } from '@core/libs/i18n/utils'
import { BButton } from 'bootstrap-vue'

export default {
  components: { BButton },
  props: {
    type: {
      type: String,
      required: true,
    },
    dataExportToExcel: {
      type: Array,
    },
    dataExportToPdf: {
      type: Array,
    },
    headerDataExportToPdf: {
      type: Array,
    },
    fileName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    titleButton: {
      type: String,
    },
  },
  computed: {
    time() {
      return moment().format('DD-MM-YYYY-HH:mm:ss').toString()
    },
  },
  methods: {
    t,
    exportTo() {
      if (this.type == 'xls') {
        const fileName = `${this.fileName}_${this.time}`
        const data = this.dataExportToExcel
        exportToExcel(data, fileName)
      } else if (this.type == 'pdf') {
        const title = `${this.title ? this.title : `${this.fileName} ${this.time}`}`
        const dataHeader = this.headerDataExportToPdf
        const data = this.dataExportToPdf
        const fileName = `${this.fileName}_${this.time}`
        const orientation = 'landscape'
        exportToPDF(title, dataHeader, data, fileName, orientation)
      }
    },
  },
}
</script>
