import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import { title } from '@/@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'
import { dateFormatMoment } from '@core/utils/utils'
import { round } from 'echarts/lib/util/number'

export default function useMembershipsList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refMembershipListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'client', label: t('Clients.clientList.columnName'), sortable: true },
    {
      key: 'plan',
      label: t('Memberships.membershipList.planName'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'cost',
      label: t('Memberships.membershipList.cost'),
      sortable: false,
    },
    {
      key: 'totalPayment',
      label: t('Memberships.membershipList.totalPayment'),
      sortable: false,
    },
    {
      key: 'debt',
      label: t('Memberships.membershipList.debt'),
      sortable: false,
    },
    {
      key: 'paymentMethod',
      label: t('Planes.planList.paymentMethod'),
      sortable: true,
    },
    {
      key: 'credits',
      label: t('Planes.planList.creditsShort'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'attendance',
      label: t('Planes.planList.attendanceShort'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'created',
      label: t('Memberships.data.created'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'start',
      label: t('Planes.planList.startDate'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'end',
      label: t('Planes.planList.endDateShort'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'active',
      label: t('Planes.planList.status'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'spacer',
      label: "",
      sortable: false,
    },
  ]

  const membershipsData = ref(null)
  const membershipsActiveData = ref(null)
  const filterDate = ref(0)
  const filterMembershipType = ref(0)
  const dateStart = ref(dateFormatMoment(new Date()))
  const dateEnd = ref(dateFormatMoment(new Date()))
  // const dateEnd = ref(dateFormatMoment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)))
  const perPage = ref(10)
  const totalMemberships = ref(0)
  const totalMembershipsActive = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const sortByActive = ref('end')
  const isSortDescActive = ref(false)
  const isSortDirDesc = ref(true)
  const planFilter = ref()
  const statusFilter = ref(null)
  const typeActive = 2 // Contrataciones activas

  const dataMeta = computed(() => {
    const localItemsCount = refMembershipListTable.value ? refMembershipListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMemberships.value,
    }
  })

  const refetchDataMembership = () => {
    refMembershipListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterMembershipType, filterDate, dateEnd, dateStart], () => {
    refetchDataMembership()
  })
  const fetchMemberships = (ctx, callback) => {
    if (searchQuery.value.length == 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-membership/fetchMemberships', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filterDate: filterDate.value,
          dateStart: dateStart.value,
          dateEnd: dateEnd.value,
          filterType: filterMembershipType.value,
        })
        .then(response => {
          const { memberships, total } = response.data
          callback(memberships)
          membershipsData.value = memberships
          totalMemberships.value = total
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response ? error.response.data.error : 'Error al listar las contrataciones',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refMembershipListTable.value.localItems)
    }
  }
  const fetchMembershipsActive = () => {
    store
      .dispatch('app-membership/fetchMemberships', {
        filterType: typeActive, // Activas
        allActive: true,
        sortBy: sortByActive.value,
        sortDesc: isSortDescActive.value,
      })
      .then(response => {
        const { memberships, total } = response.data
        membershipsActiveData.value = memberships
        totalMembershipsActive.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response ? error.response.data.error : 'Error al listar las contrataciones activas',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const titleColumn = [
    'Apellido y nombre',
    'DNI',
    'Plan',
    'Cost.',
    '% desc.',
    'Pago',
    'Debe',
    'Medio',
    'Cred.',
    'Asist.',
    'Creación',
    'Inicio',
    'Vence',
    'Estado',
    'Observación']
  const dataExport = (memberships, header) => {
    const data = []
    let nodata = '-'
    if (header && header.length > 0) {
      data.push(header)
      nodata = ''
    }
    memberships.forEach(membership => {
      const userDataArray = []
      userDataArray.push(`${membership.client.last_name}, ${membership.client.first_name}`)
      userDataArray.push(membership.client.dni ?? nodata)
      userDataArray.push(membership.plan.name ?? nodata)
      userDataArray.push(membership.cost ? round(membership.cost, 2) : nodata)
      userDataArray.push(membership.discount_percentage ?? nodata)
      userDataArray.push(membership.total_payment ? round(membership.total_payment, 2) : nodata)
      userDataArray.push(membership.debt ? round(membership.debt, 2) : nodata)
      userDataArray.push(membership.paymentMethod.name ?? nodata)
      userDataArray.push(membership.credits ?? nodata)
      userDataArray.push(membership.attendance ?? nodata)
      userDataArray.push(membership.created != null ? dateFormatMoment(membership.created) : nodata)
      userDataArray.push(membership.start != null ? dateFormatMoment(membership.start) : nodata)
      userDataArray.push(membership.end != null ? dateFormatMoment(membership.end) : nodata)
      userDataArray.push(membership.active ? t('statusMemberships.active') : t('statusMemberships.delete'))
      userDataArray.push((membership.observation ?? nodata))

      data.push(userDataArray)
    })
    return data
  }

  const editMembership = (memberships, emit) => {
    store.dispatch('app-membership/upgradeMembership', {
      id: memberships.id,
    })
      .then(async () => {
        emit('update:is-user-prepaid-sidebar-active', false)
        emit('handle-update-prepaid')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Contratación activada',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        console.log(error)
        if (error.response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolveDateStatusVariant = active => {
    if (active) return 'success'
    return 'danger'
  }
  const resolveMembershipsStatusVariant = active => {
    if (active) return 'warning'
    return 'success'
  }
  const resolveMemberships = active => {
    if (active) return t('statusMemberships.active')
    return t('statusMemberships.delete')
  }
  const resolveDateStatus = (status, expireToDay) => {
    if (status && !expireToDay) return t('statusMemberships.inactive')
    if (!status && expireToDay) return t('statusMemberships.expireToDay')
    if (status == false) return t('statusMemberships.activeDate')
    return t('statusMemberships.noMembership')
  }

  const getDate = () => moment().startOf('day')
  const formatDate = date => {
    const myMomentObject = moment(date)
    return myMomentObject.format('DD-MM-YYYY HH:mm')
  }

  const filterDateOptions = [
    { value: 0, label: 'Todas las fechas' },
    { value: 1, label: 'Fecha creación' },
    { value: 2, label: 'Fecha inicio' },
    { value: 3, label: 'Fecha vencimiento' },
  ]

  const filterTypeMembershipOptions = [
    { value: 0, label: 'Todas' },
    { value: 1, label: 'Únicas' },
    { value: 2, label: 'Activas' },
  ]
  return {
    fetchMemberships,
    formatDate,
    getDate,
    membershipsData,
    membershipsActiveData,
    tableColumns,
    titleColumn,
    dataExport,
    editMembership,
    fetchMembershipsActive,
    totalMembershipsActive,
    filterMembershipType,
    perPage,
    currentPage,
    totalMemberships,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMembershipListTable,
    filterTypeMembershipOptions,
    resolveUserRoleVariant,
    resolveMemberships,
    resolveDateStatusVariant,
    resolveMembershipsStatusVariant,
    refetchDataMembership,
    resolveDateStatus,
    // Extra Filters
    planFilter,
    statusFilter,
    filterDate,
    filterDateOptions,
    dateEnd,
    dateStart,
    t,
  }
}
