<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="mb-2">
          <b-col
            cols="12"
            md="3"
          >
            <label>{{ t('Memberships.filterTitle') }}</label>
            <v-select
              v-model="filterMembershipType"
              :value="filterMembershipType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterTypeMembershipOptions"
              :reduce="val => val.value"
              :clearable="true"
              @input="(val) => $emit('update:filterMembershipType', val)"
            />

          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <label>{{ t('Memberships.date') }}</label>
            <v-select
              v-model="filterDate"
              :value="filterDate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterDateOptions"
              :reduce="val => val.value"
              :clearable="true"
              @input="(val) => $emit('update:filterDate', val)"
            />

          </b-col>
          <b-col
            v-if="filterDate > 0"
            cols="12"
            md="3"
          >

            <!-- Start date -->
            <b-form-group
              :label="t('Memberships.filterDate.start')"
              label-for="startDate"
            >
              <flat-pickr
                id="startDate"
                v-model="dateStart"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y'}"
                placeholder="DD-MM-YYYY"
                :disabled="false"
              />
            </b-form-group>

          </b-col>
          <b-col
            v-if="filterDate > 0"
            cols="12"
            md="3"
          >

            <!-- End date -->
            <b-form-group
              :label="t('Memberships.filterDate.end')"
              label-for="endDate"
            >
              <flat-pickr
                id="endDate"
                v-model="dateEnd"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y'}"
                placeholder="DD-MM-YYYY"
                :disabled="false"
              />
            </b-form-group>
            <div
              v-if="!validateDate"
              class="alert-danger pl-1"
            >
              {{ t('validate.error.date') }}
            </div>
          </b-col>
        </b-row>

        {{ getActiveMembership }}
        <div v-if="membershipsActiveData && membershipsActiveData != undefined && showButtonExport">
          <b-row>

            <b-col cols="3">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    class="timeline-item-icon d-flex align-items-left rounded-circle"
                  >        <label>{{ t('export.membershipTitle') }}</label></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >

                  <button-export
                    type="pdf"
                    :title="`Listados de contrataciones activas - Total: ${ totalMembershipsActive }`"
                    title-button="Activas PDF"
                    :file-name="t('Memberships.title')"
                    :data-export-to-pdf="dataExport(membershipsActiveData)"
                    :header-data-export-to-pdf="titleColumn"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <button-export
                    type="xls"
                    :title="`Listados de contrataciones activas - Total: ${ totalMembershipsActive }`"
                    title-button="Activas XLS"
                    :file-name="t('Memberships.title')"
                    :data-export-to-excel="dataExport(membershipsActiveData, titleColumn)"
                  />
                </b-col>

              </b-row>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
          </b-row>

        </div>

      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-3"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ t('Clients.clientList.showTitle') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <span class="text-muted"> - {{ t('total') }}: {{ totalMemberships }}</span>
            <div v-if="membershipsData && membershipsData != undefined">
              <export-xls-pdf
                title-xls="XLS"
                title-pdf="PDF"
                :file-name="t('Memberships.title')"
                :data-export-to-excel="dataExport(membershipsData, titleColumn)"
                :data-export-to-pdf="dataExport(membershipsData)"
                :header-data-export-to-pdf="titleColumn"
              />
            </div>

          </b-col>
          <b-col
            cols="12"
            md="4"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                :maxlength="30"
                class="d-inline-block mr-1"
                :placeholder="$t('Memberships.searchPlaceholder')+' ...'"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMembershipListTable"
        class="position-relative"
        :items="fetchMemberships"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="t('Memberships.message.no-membership')"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.id.toString()"
                :text="avatarText()"
                :variant="`light-${resolveUserRoleVariant(data.item.client.id)}`"
                :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              class="font-weight-bold d-block"
            >
              {{ data.item.client.last_name + ", " + data.item.client.first_name }}
            </b-link>
            <small class="text-muted">{{ data.item.client.dni }}</small>
          </b-media>
        </template>

        <!-- Column: Plan -->
        <template #cell(plan)="data">
          <small class="text-muted">
            {{ (data.item.plan.name) }}
          </small>
          <b-badge
              v-if="(data.item.total_payment != data.item.cost ) && (data.item.discount_percentage !=null) && (data.item.discount_percentage > 0)"
              variant="light-info"
          >
            {{ data.item.discount_percentage + "% off" }}
          </b-badge>
        </template>

        <!-- Column: membership plan costo -->
        <template #cell(cost)="data">
          <h6 class="text-muted text-center">
            {{ (data.item.cost ? "$" + data.item.cost : "-") }}
          </h6>
        </template>
        <!-- Column: membership plan total payment -->
        <template #cell(totalPayment)="data">
          <h6 class="text-muted text-center">
            {{ (data.item.total_payment ? "$" + round(data.item.total_payment, 2) : "-") }}
          </h6>
        </template>

        <!-- Column: membership debt -->
        <template #cell(debt)="data">
          <h6 class="text-muted text-center">
            <span v-if="data.item.debt > 0">
              <b-badge variant="light-warning">
                {{ ("$" + data.item.debt) }}
              </b-badge>
            </span>
            <span v-else> -
            </span>
          </h6>
        </template>
        <!-- Column: payment method -->
        <template #cell(paymentMethod)="data">
          <h6 class="text-muted text-center">
            <span v-if="data.item.paymentMethod != null">
                {{data.item.paymentMethod.name}}
            </span>
            <span v-else> -
            </span>
          </h6>
        </template>
        <!-- Column: credits -->
        <template #cell(credits)="data">
          <span v-if="data.item.attendance !== data.item.credits ">
            <h6 class="text-muted text-center">{{ (data.item.credits) }}</h6>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              {{ (data.item.credits) }}
            </b-badge>
          </span>
        </template>

        <!-- Column: assitences  -->
        <template #cell(attendance)="data">
          <span v-if="data.item.attendance != data.item.credits ">
            <h6 class="text-muted text-center">{{ (data.item.attendance) }}</h6>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              {{ (data.item.attendance) }}
            </b-badge>
          </span>
        </template>

        <!-- Column: date created -->
        <template #cell(created)="data">
          <h6 class="text-muted text-center">
            {{ data.item.created ? dateLatin(data.item.created): "-" }}
          </h6>
        </template>

        <!-- Column: date start -->
        <template #cell(start)="data">
          <h6 class="text-muted text-center">
            {{ dateLatin(data.item.start) }}
          </h6>
        </template>

        <!-- Column: date end -->
        <template #cell(end)="data">
          <b-media vertical-align="center">
            <h6 class="text-normal text-center">
              {{ dateLatin(data.item.end) }}
            </h6>
            <b-badge
              pill
              :variant="`light-${resolveMembershipsStatusVariant(data.item.isExpired || data.item.isExpiresToday)}`"
              class="text-capitalize"
            >
              <small>{{ resolveDateStatus(data.item.isExpired, data.item.isExpiresToday) }}</small>
            </b-badge>
          </b-media>
        </template>

        <template #cell(active)="data">
          <b-media vertical-align="center">
            <h6 class="text-normal text-center">
              <b-badge
                pill
                :variant="`light-${resolveDateStatusVariant(data.item.active)}`"
                class="text-capitalize"
              >
                <small>{{ resolveMemberships(data.item.active) }}</small>
              </b-badge>
            </h6>
          </b-media>
        </template>

        <!-- Column de espacio -->
        <template><small></small></template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Clients.clientList.dataMeta') }}: {{ dataMeta.to }} - {{ t('total') }}: {{ totalMemberships }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMemberships"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BFormInput, BFormGroup, BCardBody, BButton, BIcon,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText, dateLatin } from '@core/utils/filter'
import { round } from 'echarts/src/util/number'
import useMembershipsList from '@/views/Memberships/memberships-list/useMembershipsList'
import store from '@/store'
import ExportXlsPdf from '@/layouts/components/ExportXlsPdf.vue'
import { isSameOrAfter } from '@core/utils/utils'
import ButtonExport from '@/layouts/components/ButtonExport.vue'
import membershipStoreModule from '@/views/Memberships/membershipStoreModule'

export default {

  components: {
    ButtonExport,
    BIcon,
    BButton,
    BCardBody,
    flatPickr,
    BFormGroup,
    ExportXlsPdf,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  props: {
  },
  computed: {
    getActiveMembership() {
      if (this.filterMembershipType == 2) {
        this.fetchMembershipsActive()
        this.setShowButton(true)
      } else {
        this.setShowButton(false)
      }
    },
    validateDate() {
      return isSameOrAfter(this.dateStart, this.dateEnd)
    },
  },
  methods: {
    round,
    selectMembership(item) {
      this.selectedMembership = item
      this.isCopyMembership = true
      this.isAddMembershipSidebarActive = true
    },

    editSelectMembership(item) {
      this.selectedMembership = item
      this.isEditMembershipSidebarActive = true
    },

    deleteSelectMembership(item) {
      this.selectedMembership = item
      this.isDeleteMembershipSidebarActive = true
    },

    setShowDataForm() {
      this.isCopyMembership = false
      this.isAddMembershipSidebarActive = true
    },
    setShowButton(value) {
      this.showButtonExport = value
    },
  },

  setup() {
    const MEMBERSHIP_APP_STORE_MODULE_NAME = 'app-membership'
    // Register module
    if (!store.hasModule(MEMBERSHIP_APP_STORE_MODULE_NAME)) store.registerModule(MEMBERSHIP_APP_STORE_MODULE_NAME, membershipStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBERSHIP_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBERSHIP_APP_STORE_MODULE_NAME)
    })
    const selectedMembership = ref()
    const isAddMembershipSidebarActive = ref(false)
    const isEditMembershipSidebarActive = ref(false)
    const isDeleteMembershipSidebarActive = ref(false)
    const isCopyMembership = ref(false)
    const localListMembershipsAssosied = ref()
    const showButtonExport = ref(false)

    const {
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,

      fetchMemberships,
      refMembershipListTable,
      searchQuery,
      totalMemberships,
      membershipsData,
      titleColumn,
      dataExport,
      filterDate,
      filterDateOptions,
      filterTypeMembershipOptions,
      dateEnd,
      dateStart,
      // UI
      resolveMemberships,
      totalMembershipsActive,
      fetchMembershipsActive,
      membershipsActiveData,
      filterMembershipType,
      resolveMembershipsStatusVariant,
      resolveDateStatusVariant,
      resolveUserRoleVariant,
      resolveDateStatus,
      t,
    } = useMembershipsList()

    return {
      membershipsActiveData,
      selectedMembership,
      fetchMembershipsActive,
      totalMembershipsActive,
      localListMembershipsAssosied,
      isAddMembershipSidebarActive,
      isEditMembershipSidebarActive,
      isDeleteMembershipSidebarActive,
      isCopyMembership,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showButtonExport,
      fetchMemberships,
      refMembershipListTable,
      searchQuery,
      totalMemberships,
      membershipsData,
      titleColumn,
      dataExport,

      // Filter
      avatarText,
      dateLatin,
      filterDate,
      filterDateOptions,
      filterTypeMembershipOptions,
      dateEnd,
      dateStart,
      // UI
      resolveMemberships,
      filterMembershipType,
      resolveMembershipsStatusVariant,
      resolveDateStatusVariant,
      resolveUserRoleVariant,
      resolveDateStatus,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
